<template>
    <div v-if="showValidationModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-center justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-white w-full   text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                                
                                  <ul>
                                  <li class="text-center">{{ this.modalMsg }}</li>
                                </ul>
                                            
                                                
                                </div>
                                                
                            <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="closeModal" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                                
                            </div>
                            
                        </div>
                        </div> 
                    </div>
                    </div>    
  <div class="grid grid-cols-12 ">
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>اطلاعات کاربر </p>
      <p>{{ this.getUID }}  </p>
     
    </div>
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <div class="account-title grid grid-cols-3 justify-self-center  ">
                       
                       <div class="col-span-3 lg:col-span-1 grid grid-rows-11   my-2 p-1 sm:py-6 sm:px-4 ">

                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      نام فارسی   
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.persianFirstName}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      نام خانوادگی فارسی   
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.persianLastName}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      نام انگلیسی   
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.englishFirstName}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      نام خانوادگی انگلیسی   
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.englishLastName}}
                               </div>
                           </div>
                           
                          </div>
                       <div class="col-span-3 lg:col-span-1 grid grid-rows-11   my-2 p-1 sm:py-6 sm:px-4 ">

                           <div class="flex items-center space-x-  my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      کد ملی    
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.nationCode}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      یوزرنیم     
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.userName}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                    ایمیل    
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.email}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      شماره تلفن
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.phoneNumber}}
                               </div>
                           </div>
                           
                          </div>
                       <div class="col-span-3 lg:col-span-1 grid grid-rows-11   my-2 p-1 sm:py-6 sm:px-4 ">

                           <div class="flex items-center space-x-4  my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                      تاریخ عضویت     
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10)}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                       
                                    تائید تلفن

                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.phoneNumberConfirmed}}
                               </div>
                           </div>
                           <div class="flex items-center space-x-4 my-2  ">
                              
                               <div class="flex-1 min-w-0">
                                   <p class="text-sm text-indigo-900 mx-2 truncate ">
                                    تائید ایمیل    
                                   </p>
                               
                               </div>
                               <div  class="inline-flex items-center  text-indigo-900 ">
                                   {{ this.emailConfirmed}}
                               </div>
                           </div>
                           
                           
                          </div>
                          </div>
    </div>
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
        <div class="flex justify-between items-center">
            <p> احراز هویت</p>
            <p v-if="filled ==''" class="bg-gray-300 text-gray-600 rounded rounded-lg px-2 py-1 text-xs ">احراز نشده</p>
            <p v-if="filled && !verificationChecked " class="bg-orange-300 text-orange-600 rounded rounded-lg px-2 py-1 text-xs ">در حال بررسی</p>
            <p v-if="filled && verificationChecked && !verified " class="bg-red-300 text-red-600 rounded rounded-lg px-2 py-1 text-xs ">عدم تائید مدارک</p>
            <p v-if="filled && verificationChecked && verified " class="bg-green-300 text-green-600 rounded rounded-lg px-2 py-1 text-xs ">تائید احراز</p>

          </div>
          
          <div class="grid grid-cols-3 gap-x-2 mt-4">

            <div class=" col-span-3 items-center justify-start w-full">
              <p> کد ملی: </p>
              <p>{{ this.nationalCode }}</p>
            </div>
            <div class="lg:col-span-1 col-span-3 items-center justify-center w-full">
              <p> تصویر روی کارت ملی </p>
              
                <label   class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg  bg-gray-50 hover:bg-gray-100 ">

                  
                  <img v-if="imageUrl1" :src="'data:image/jpeg;base64,' + imageUrl1" alt="Image" class="max-w-full max-h-full p-2">
                </label>
           </div> 
            <div class="lg:col-span-1 col-span-3  items-center justify-center w-full">
              <p> تصویر پشت کارت ملی </p>
              
                <label   class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50 hover:bg-gray-100 ">

                  
                  <img v-if="imageUrl2" :src="'data:image/jpeg;base64,' + imageUrl2" alt="Image" class="max-w-full max-h-full p-2">
                  
                </label>
                
           </div> 
           <div class="lg:col-span-1 col-span-3  items-center justify-center w-full">
              <p> تصویر کاربر به همراه کارت ملی </p>
              
                <label   class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg  bg-gray-50 hover:bg-gray-100 ">

                  
                  <img v-if="imageUrl3 " :src="'data:image/jpeg;base64,' + imageUrl3" alt="Image" class="max-w-full max-h-full p-2">
                  
                </label>
           </div>
           
           <div  class="col-span-3 flex items-center space-x-4 my-2    align-middle text-gray-500 ">
                    <div v-if="filled" class="flex items-center mx-2">
                        <button @click="acceptValidation" class="bg-green-100 text-white text-sm rounded-lg px-2 py-1 m-2">تائید مدارک</button>
                        <button @click="rejectValidation" class="bg-gray-800 text-white text-sm rounded-lg px-2 py-1 m-2">رد مدارک</button>
    
                    </div>
            <div>
    </div>
    </div>
    </div>
    </div>
    <!-- etelaat hesab -->
    <div class="col-span-12  bg-surface-card border border-surface-border p-4 m-4 mb-1 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      
        <p>اطلاعات حساب‌ها </p>
        <button @click="showNewAccountDivFunction()" class="align-self-center py-2 my-2 px-3 font-medium tracking-wide text-gray-900 transition duration-200 border-2 border-gray-900 rounded-lg hover:bg-gray-800 hover:text-white ">
         ساخت حساب جدید 
        </button>
        
      

     
    </div>
    <div v-if="this.showNewAccountDiv" class="col-span-12 lg:col-span-6 bg-surface-card border border-surface-border p-4 m-4 mb-1 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <AdminNewChallenge/>

     
    </div>
    <div  class="col-span-12  bg-surface-card border border-surface-border  m-4 mb-1 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <ATicketsUser/>

     
    </div>

    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p> ایجاد کد تخفیف  </p>
      <div class="w-full grid grid-cols-2  py-1  m-1 rounded">
      <button @click="whichCode('user')" :class="{ 'bg-gray-100  text-gray-800': this.DisCode !== 'user','bg-TFL-base1  text-white': this.DisCode === 'user' }" class="col-span-1 py-1 px-2 mx-1 rounded text-sm"> استفاده شخصی</button>
        <button @click="whichCode('referral')" :class="{ 'bg-gray-100  text-gray-800': this.DisCode !== 'referral','bg-TFL-base1  text-white': this.DisCode === 'referral' }" class="col-span-1 py-1 px-2 mx-1 rounded  text-sm">رفرال  </button>
        
        </div>
      <div class="grid grid-cols-10">
        <div class="col-span-2  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">کد</label>
          <input v-model="code" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="col-span-2  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">درصد تخفیف</label>
          <input v-model="percent" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="col-span-2  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">حداکثر مبلغ تخفیف</label>
          <input v-model="maxAmount" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="col-span-2  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">حداکثر تعداد تخفیف</label>
          <input v-model="useLimit" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="col-span-2  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">انقضا</label>
          <input v-model="expireTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="col-span-4  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">کامنت</label>
          <input v-model="comment" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="col-span-2  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">سهم رفرال</label>
          <input v-model="referralSharePercent" :disabled="DisCode=='user'" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class=" col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">پلن</label>
          <div v-for="option in plansOptions" :key="option.text">
            <input type="checkbox" :value="option.text" v-model="plans">
            {{ option.text }}
          </div>
          
        </div>
        <div class=" col-span-12  mx-1">
          
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">دیپازیت</label>
          <div v-for="option1 in DepositsOptions" :key="option1.text">
            <input type="checkbox" :value="option1.text" v-model="deposits">
            {{ option1.text }}
          </div>
          
        </div>
        <div class="col-span-2  mx-1">
            <button @click.prevent="createDiscountCode" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-5">
                          ایجاد تخفیف
                         
            </button>
      </div>
      </div>
      </div>
      <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>کد رفرال</p>
     
   
      <div  class=" col-span-12 min-w-48 "> 
            <div class="  grid grid-cols-2 ">
                <div v-for="(referral, index) in referrals" :key="referral.id" class="account-section bg-TFL-cardDashboard overflow-auto min-w-48  p-4 m-2  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg col-span-2 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px] flex justify-between   ">
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">#</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{ formatNumber(index+1) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">کد تخفیف</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{ referral.disCountCode }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">درصد کمیسیون</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralSharePercent) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">کل مبلغ خریداری شده</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralTotalPricePayed) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">مبلغ کمیسیون</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralWithdrawAmount) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">تعداد خرید</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralBuysCount) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">وضعیت </p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 " v-if="referral.referralWithdrawAmount == 0 && referral.referralTotalPricePayed != 0">تسویه شده</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 " v-if="referral.referralWithdrawAmount == 0 && referral.referralTotalPricePayed == 0"> - </p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 " v-if="referral.referralWithdrawAmount != 0 && referral.referralTotalPricePayed != 0"> در انتظار تسویه </p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">تسویه </p>
                        <button @click="referralWithdraw(referral.discountUid)" class="bg-green-700 rounded-lg p-1 text-white">ثبت تسویه </button>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <div class="grid grid-cols-12 col-span-12">
        <div class=" col-span-12 "> 
            <div class="  grid grid-cols-1 ">
                <div v-for="(account, index) in userAccounts" :key="account.id" class="account-section border rounded-lg border-surface-border bg-white p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] col-span-2 lg:col-span-1 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px]   ">
                    <!-- Modal Account Details -->
                    <div v-if="account.showDetails" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                           
                        <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="col-span-3 lg:col-span-2    m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">

                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                            شماره حساب
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        {{account.accountId}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                             پسورد
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        {{account.password}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                             اینوستور پسورد
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        {{account.investorPassword}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                             بروکر 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        {{account.broker}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                             سرور 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        {{account.server}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                             پلتفرم 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        {{account.platform}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-indigo-900 truncate ">
                                                             لورج 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-indigo-900 ">
                                                        1:{{account.leverage}}
                                                    </div>
                                                    <!-- <div class="flex-shrink-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                        </svg>

                                                    </div> -->
                                                </div>
                                                <hr class="h-px my-2 bg-gray-200 border-0 ">
                                                
                                                </div>
                            <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                            <button @click="toggleAccount(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-200 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-100 sm:ml-3 sm:w-auto">بستن</button>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <!-- Modal Fail -->
                    <div v-if="account.showFail" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                        
                        <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="col-span-3 lg:col-span-2 text-right   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">

                                                <div class="flex items-center space-x-4 my-2 ">
                                                     واقعا میخوای فیلش کنی؟
                                                </div>
                                                <textarea  v-model="failedComment" id="chat" rows="6" class="block mx-1 bg-gray-600 bg-opacity-50 p-2.5 w-full text-sm text-gray-300  rounded-lg   focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>

                                                
                                                </div>
                                                
                            <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showFailModalFunction(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 sm:ml-3 sm:w-auto">بستن</button>
                                <button  @click="failFunction(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">فیلش کن  </button>
                                
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    </div>

                    <!-- Modal Extend -->
                    


<!-- khode card -->
                    <div class="account-title w-full grid grid-cols-3 justify-self-center  ">
                       
                        <div class="relative col-span-3 lg:col-span-1 grid grid-rows-11   my-2 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow sm:py-6 sm:px-8 ">
                            <div v-if="account.plan == 'Limited'" class="absolute  -top-2 mx-8 px-1 text-sm bg-purple-200 rounded text-purple-800">دارای محدودیت زمانی 8,4</div>
                            <div v-if="account.plan == 'Limited6'" class="absolute  -top-2 mx-8 px-1 text-sm bg-purple-200 rounded text-purple-800">دارای محدودیت زمانی 6,6</div>
                            <div v-if="account.plan == 'NoLimit'" class="absolute  -top-2 mx-8 px-1 text-sm bg-green-200 bg-opacity-30 rounded text-green-800">بدون محدودیت زمانی 8,4</div>
                            <div v-if="account.plan == 'NoLimit6'" class="absolute  -top-2 mx-8 px-1 text-sm bg-green-200 bg-opacity-30 rounded text-green-800">بدون محدودیت زمانی 6,6</div>
                            <div v-if="account.plan == 'Rapid'" class="absolute  -top-2 mx-8 px-1 text-sm bg-orange-200 bg-opacity-50 rounded text-orange-800">مستقیم  </div>
                            <div v-if="account.plan == 'Multiple'" class="absolute  -top-2 mx-8 px-1 text-sm bg-blue-200 bg-opacity-50 rounded text-blue-800">5X  </div>
                            <div v-if="account.plan == 'Competition'" class="absolute  -top-2 mx-8 px-1 text-sm bg-red-300 bg-opacity-50 rounded text-red-800">مسابقه  </div>
<!-- <div v-if="check"> </div> -->
                            <div class="flex items-center space-x-4 my-2  ">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                         شماره حساب
                                    </p>
                                
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.accountId}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                    </svg>


                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                          موجودی اولیه
                                    </p>
                                
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.deposit}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                    </svg>


                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                           دلیل فیل
                                    </p>
                                
                                </div>
                                <div v-if="account.failedBy" class="inline-flex items-center  text-indigo-900 ">
                                    {{account.failedBy}}
                                </div>
                                <div v-if="!account.failedBy" class="inline-flex items-center  text-indigo-900 ">
                                   -
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                           وضعیت
                                    </p>
                                
                                </div>
                                <div v-if="account.state === 'Active'" class="inline-flex items-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50 ">
                                    <!-- {{account.state}} -->
                                     فعال
                                </div>
                                <div v-if="account.state === 'Failed'" class="inline-flex items-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    مردود
                                </div>
                                <div v-if="account.state === 'ReviewNeeded'" class="inline-flex items-center bg-orange-300 text-orange-600 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    بررسی کارشناس
                                </div>
                                <div v-if="account.state === 'Reseted'" class="inline-flex items-center bg-gray-400 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    ریست شده
                                </div>
                                <div v-if="account.state === 'Expired'" class="inline-flex items-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    منقضی شده
                                </div>
                                <div v-if="account.state === 'SucceedMinProfit'" class="inline-flex items-center bg-purple-500 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    تارگت سود
                                </div>
                                <div v-if="account.state === 'PendingNextPhase'" class="inline-flex items-center bg-purple-500 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    تارگت سود
                                </div>
                                <div v-if="account.state === 'Passed'" class="inline-flex items-center bg-blue-500 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-opacity-50">
                                    پاس
                                </div>
                                
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                        فاز
                                    </p>
                                </div>
                                <div v-if="account.type == 'DemoPhase1'" class="inline-flex items-center  text-indigo-900 ">
                                    فاز اول
                                </div>
                                <div v-if="account.type == 'DemoPhase2'" class="inline-flex items-center  text-indigo-900 ">
                                    فاز دوم
                                </div>
                                <div v-if="account.type == 'Real'" class="inline-flex items-center  text-indigo-900 ">
                                    ریل 
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p  class="text-sm text-indigo-900 mx-2 truncate ">
                                        تاریخ ایجاد حساب
                                    </p>
                                </div>
                                <div v-if="account.createDateTime" class="inline-flex items-center  text-indigo-900 ">
                                    {{ account.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) }}
                                </div>
                                
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p  class="text-sm text-indigo-900 mx-2 truncate ">
                                        تاریخ شروع
                                    </p>
                                </div>
                                <div v-if="account.firstTradeDateTime" class="inline-flex items-center  text-indigo-900 ">
                                    {{ account.firstTradeDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) }}
                                </div>
                                <div v-if="!account.firstTradeDateTime" class="inline-flex items-center  text-indigo-900 ">
                                   در انتظار اولین ترید
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p   class="text-sm text-indigo-900 mx-2 truncate ">
                                        تاریخ پایان
                                    </p>
                                
                                </div>
                                <div v-if="account.expireTime" class="inline-flex items-center  text-indigo-900 ">
                                    {{account.expireTime.replace("T", " at ").replace("+00:00", "").substring(0, 10)}}
                                </div>
                                <div v-if="!account.expireTime" class="inline-flex items-center  text-indigo-900 ">
                                   -
                                </div>
                            </div>
                            
                                
                    
                                
                            
                            
                        </div>
                        <div class="col-span-3 lg:col-span-1 grid grid-rows-11   my-2 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow sm:py-6 sm:px-8 ">

                            <div class="flex items-center space-x-4 my-2  ">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                          بالانس
                                    </p>
                                
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.balance}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                    </svg>


                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                        توانایی اکستند 
                                    </p>
                                
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.canExtend}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                           توانایی ریست
                                    </p>
                                
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.canReset}}
                                </div>
                                
                                
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-indigo-900 mx-2 truncate ">
                                        اکستند شده؟
                                    </p>
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.extended}}
                                </div>
                                
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p  class="text-sm text-indigo-900 mx-2 truncate ">
                                         توانایی برداشت
                                    </p>
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.canWithdrawal}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p  class="text-sm text-indigo-900 mx-2 truncate ">
                                          برداشت داشته؟
                                    </p>
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.isWithdrew}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-200 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p  class="text-sm text-indigo-900 mx-2 truncate ">
                                          قیمت خرید 
                                    </p>
                                </div>
                                <div class="inline-flex items-center  text-indigo-900 ">
                                    {{account.pricePaid.toFixed(2)}}
                                </div>
                            </div>
                            
                                
                    
                                
                            
                            
                        </div>
                        <div class="col-span-3  lg:col-span-1    my-2 p-2  bg-gray-50 border border-gray-200 rounded-lg shadow    mx-2 grid grid-rows-5">
                            <router-link :to="{params: {id:account.uId }, name:'DAdminAnalysis' }"  class="relative inline-flex items-center justify-center px-2 py-2 my-2 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-green-800 hover:border-2 hover:border-green-200 rounded-lg shadow-md group">
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 translate-x-full bg-green-200 group-hover:translate-x-0 ease">
                                    <svg class="w-6 h-6 flip-right-to-left" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </span>
                                <span class="absolute flex items-center justify-center w-full h-full text-green-800 transition-all duration-300 transform group-hover:translate-x-full ease"> آنالیز حساب</span>
                                <span class="relative invisible">Button Text</span>
                            </router-link>
                            <button  @click="showFailModalFunction(index)"  class="inline-flex  items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-900 transition duration-200 border-2 border-gray-900 rounded-lg hover:bg-gray-800 hover:text-white ">
                             فیل 
                            </button>
                            <!-- <button @click="showExtendFunction(index)" class="inline-flex items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-900 transition duration-200 border-2 border-gray-900 rounded-lg hover:bg-gray-800 hover:text-white ">
                             درخواست اکستند
                            </button>
                            <button v-if="account.type != 'Real'" @click="showNextPhaseFunction(index)" to="/Dashboard/DWhitdrawal" class="inline-flex items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-900 transition duration-200 border-2 border-gray-900 rounded-lg hover:bg-gray-800 hover:text-white ">
                             درخواست فاز بعدی
                            </button>
                            <router-link v-if="account.type == 'Real'" to="/Dashboard/DWhitdrawal" class="inline-flex items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-900 transition duration-200 border-2 border-gray-900 rounded-lg hover:bg-gray-800 hover:text-white ">
                             درخواست برداشت
                            </router-link> -->
                            <button  @click="toggleAccount(index)" class="relative  py-2 my-2 items-center  justify-center font-medium text-white text-center transition duration-300 bg-green-200 rounded-md hover:bg-green-100 ease">
                            <span class="absolute bottom-0 left-0 h-full -ml-2">
                            <svg viewBox="0 0 487 487" class="w-auto h-full opacity-100 object-stretch" xmlns="http://www.w3.org/2000/svg"><path d="M0 .3c67 2.1 134.1 4.3 186.3 37 52.2 32.7 89.6 95.8 112.8 150.6 23.2 54.8 32.3 101.4 61.2 149.9 28.9 48.4 77.7 98.8 126.4 149.2H0V.3z" fill="#FFF" fill-rule="nonzero" fill-opacity=".1"></path></svg>
                            </span>
                            <span class="absolute top-0 right-0 w-12 h-full -mr-3">
                            <svg viewBox="0 0 487 487" class="object-cover w-full h-full" xmlns="http://www.w3.org/2000/svg"><path d="M487 486.7c-66.1-3.6-132.3-7.3-186.3-37s-95.9-85.3-126.2-137.2c-30.4-51.8-49.3-99.9-76.5-151.4C70.9 109.6 35.6 54.8.3 0H487v486.7z" fill="#FFF" fill-rule="nonzero" fill-opacity=".1"></path></svg>
                            </span>
                            <span class="relative items-center justify-center text-center "> اطلاعات حساب</span>
                            </button>
                            


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   


    
        
    

     
    </div>
    
  
</template>
<script>
import axios from 'axios';
import AdminNewChallenge from '@/components/AdminNewChallenge.vue';
import URL from "../URL";
import ATicketsUser from './ATicketsUser.vue';
export default {
  name: 'DAHome',
    components: { AdminNewChallenge, ATicketsUser },
  data(){
    return{
        referrals:[],
        refLoaded: false,
        plansOptions: [
              { text: 'Limited', value: 'Limited' },
              { text: 'Limited6', value: 'Limited6' },
              { text: 'NoLimit', value: 'NoLimit' },
              { text: 'NoLimit6', value: 'NoLimit6' },
              { text: 'Rapid', value: 'Rapid' },
              
            ],
            selectedPlansOptions: [],
          DepositsOptions: [
              { text: 'K1', value: 'K1' },
              { text: 'K5', value: 'K5' },
              { text: 'K10', value: 'K10' },
              { text: 'K25', value: 'K25' },
              { text: 'K50', value: 'K50' },
              { text: 'K100', value: 'K100' }
            ],
            selecteDDepositsOptions: [],

      DisCode:'user',
      getUID: this.$route.params.id,
      userAccounts:[],
      userWithdraw:[],
      userInformation:[],
      showNewAccountDiv: false,
      persianLastName: '',
      persianFirstName: '',
      englishFirstName: '',
      nationCode: '',
      englishLastName: '',
      createDateTime: '',
      userName: '',
      email: '',
      phoneNumber: '',

      emailConfirmed: false,
      phoneNumberConfirmed: false,

      imageUrl1: null,
      imageUrl2: null,
      imageUrl3: null,

      base64Data1: null,
      base64Data2: null,
      base64Data3: null,
      nationalCode: '',
      verificationChecked: false,
      verified: false,

      isChecked: false,
      filled: false,
      filledForBadge: false,
      PageSize: 50,

    code: '',
    comment: '',
    percent: '',
    maxAmount: '',
    userId: this.$route.params.id,
    useLimit: '',
    expireTime: '',
    showValidationModal:false,
    referralUserId: null,
    plans:[],
    deposits:[],

    failedComment: ''
    }
  },
  mounted(){
    this.fetchUserInformation();
    this.fetchUserAccounts();
    this.fetchUserWithdraw();
    this.fetchReferral();
    
  },
  methods: {
    formatMessage(message) {
    return message.replace(/\n/g, '<br>');
  },
    formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    fetchReferral(){
            const url = URL.baseUrl + `Admin/discounts/referral/status/${this.userId}`;
            axios.get(url, {
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => {
        if(response.data){

            this.referrals = response.data;
            
        }
        // Add the show variable to each account object
        this.refLoaded = true
    })
    .catch(error => {
        this.refLoaded = true 
    });

        },
    whichCode(value){
      
      this.DisCode = value;
      if (this.DisCode == 'user'){
        this.userId= this.$route.params.id;
        this.referralUserId= null;
        this.referralSharePercent = null;
      }
      if (this.DisCode == 'referral'){
        this.userId= null;
        this.referralUserId= this.$route.params.id;
        this.referralSharePercent = 5;
      }
    },
    closeModal() {
      this.showValidationModal = false;

    },
    toggleAccount(index) {
      this.userAccounts[index].showDetails = !this.userAccounts[index].showDetails;
    },
    showNewAccountDivFunction() {
        this.showNewAccountDiv = !this.showNewAccountDiv;
    },
    showFailModalFunction(index){
      this.userAccounts[index].showFail = !this.userAccounts[index].showFail;
    },
    referralWithdraw(RefId) {
        const url = URL.baseUrl + `Admin/discounts/referral/withdraw/${RefId}`;
  axios.post(url, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    this.modalMsg ='تسویه با موفقیت انجام شد';
    this.showValidationModal = true;
    
  })
  .catch(error => {
    this.modalMsg ='خطا در  تسویه';
    this.showValidationModal = true;
    
  });
},
    failFunction(index) {
        const url = URL.baseUrl + `Admin/account/${this.userAccounts[index].uId}/Fail?failedComment=${this.failedComment}`;
  axios.put(url, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    // Handle successful response here
    console.log(response.data);
  })
  .catch(error => {
    this.errorMessage = error.message;
    console.error("There was an error!", error);
  });
},
    acceptValidation() {
        const url = URL.baseUrl + `users/accept-validation/${this.userId}`;
  axios.put(url, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    // Handle successful response here
    this.modalMsg ='تائید مدارک با موفقیت انجام شد';
    this.showValidationModal = true;
    this.fetchUserInformation();
  })
  .catch(error => {
    this.modalMsg ='خطا در تائید مدارک';
    this.showValidationModal = true;
    
  });
},
    rejectValidation() {
        const url = URL.baseUrl + `users/reject-validation/${this.userId}`;
  axios.put(url, '', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    // Handle successful response here
    this.modalMsg ='رد مدارک با موفقیت انجام شد';
    this.showValidationModal = true;
    this.fetchUserInformation();
  })
  .catch(error => {
    this.modalMsg ='خطا در رد مدارک';
    this.showValidationModal = true;
    
  });
},
    fetchUserAccounts(){
        const url = URL.baseUrl + `Admin/accounts?UserId=${this.getUID}&PageSize=${this.PageSize}`;
      axios.get(url,
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
        // Add the show variable to each account object
        this.userAccounts = response.data.map(account => {
        return {
            ...account,
            showDetails: false,
            showFail: false,
            showExtend: false,
            showNextPhase: false,
        };
        });
    })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
    },
    fetchUserWithdraw(){
        const url = URL.baseUrl + `Admin/withdraw?UserId=${this.getUID}`;
      axios.get(url,
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
              this.userWithdraw = response.data;
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
    },
    fetchUserInformation(){
        const url = URL.baseUrl + `users/with-validations?Guid=${this.getUID}`;
        axios.get(url,
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
              this.userInformation = response.data;
              this.persianLastName = this.userInformation[0].persianLastName;
              this.persianFirstName = this.userInformation[0].persianFirstName;
              this.englishFirstName = this.userInformation[0].englishFirstName;
              this.nationalCode = this.userInformation[0].nationCode;
              this.englishLastName = this.userInformation[0].englishLastName;
              this.createDateTime = this.userInformation[0].createDateTime;
              this.userName = this.userInformation[0].userName;
              this.email = this.userInformation[0].email;
              this.phoneNumber = this.userInformation[0].phoneNumber;

              this.base64Data1 = this.userInformation[0].nationalCardImageBase64;
         this.base64Data2 = this.userInformation[0].nationalCardBackImageBase64;
         this.base64Data3 = this.userInformation[0].personallyImageBase64;
         this.verificationChecked = this.userInformation[0].verificationChecked;
         this.verified = this.userInformation[0].verified;

        this.detailLoaded = true;

        if (this.userInformation[0].nationalCardImageBase64) {
          this.imageUrl1 = this.userInformation[0].nationalCardImageBase64;
        }
        if (this.userInformation[0].nationalCardBackImageBase64) {
          this.imageUrl2 = this.userInformation[0].nationalCardBackImageBase64;
        }
        if (this.userInformation[0].personallyImageBase64) {
          this.imageUrl3 = this.userInformation[0].personallyImageBase64;
        }
        if (this.nationalCode){
          this.filled = true;
          
        }
              
              
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
      },
      createDiscountCode(){
        const url = URL.baseUrl + `Admin/discount`;
        axios.post(url,
         { 
            
             
            
            code: this.code,
            comment: this.comment,
            percent: this.percent,
            maxAmount: this.maxAmount,
            userId: this.userId,
            useLimit: this.useLimit,
            expireTime: this.expirerTime,
            referralUserId: this.referralUserId,
            referralSharePercent: this.referralSharePercent,
            plans: this.plans,
            deposits: this.deposits,

             },
             {
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   }
             
         
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
             this.fetchDiscountCodeList();
             
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
      },
    }

  
}
</script>
